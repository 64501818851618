import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Box, Card, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {OperationWizardCardProps, OperationWizardSteps} from "../createOperationWizard/operationWizardSteps";
import {deleteBanner, deleteFavicon, deleteLogo, putOperation, uploadFile} from "../../services/OperationService";
import {CURRENT_OPERATION, NEXT_STEP, SET_OPERATION} from "../../common/methods/context-setter/globals";
import {operationStoreReducer} from "../../common/methods/context-setter/operationStoreReducer";
import {StoreContext, WizardType} from "../../common/struct/store";
import {AppContext} from "../../App";
import {PrimaryBigButton, SecondarySmallButton} from "../../components/buttons/mainButton";
import PhotoIcon from "@mui/icons-material/Photo";
import {IconPosition} from "../../components/buttons/buttonProps";
import DownloadFileDialog from "../../patterns/dialog/downloadFileDialog";
import {FileType} from "../../patterns/dialog/downloadFileProps";
import {ContrastColor, DEFAULT_PRIMARY_COLOR, extractContrastColor, FO_DARK_CONTRAST_COLOR} from "./operationColors";
import ImagePreviewWizard, {ImagePreviewWizardProps} from "../../patterns/preview/imagePreviewWizard";
import {ColorResult} from "react-color";
import {OperationColorSwitch} from "./operationColorSwitch";
import OperationColorPicker from "./operationColorPicker";
import {setStepToValidateWizardStore} from "../../common/methods/context-setter/wizardStoreReducer";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CropSettingsProps} from '../../patterns/dialog/cropImageElement';
import {useNavigate} from "react-router-dom";

export default function OperationShowcaseCard(props: OperationWizardCardProps): JSX.Element {
  const {t} = useTranslation();
  const {propsNoWizard, setError, loading, setLoading, sx} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  useEffect(() => {
    document.title = t("tab_title.wizard.step2");
  }, []);
  const actualOperationId = store.operationWizard.operation?.id??store.global.currentOperation?.id;
  const [openLogoDialog, setOpenLogoDialog] = useState(false);
  const [openBannerDialog, setOpenBannerDialog] = useState(false);
  const [openFaviconDialog, setOpenFaviconDialog] = useState(false);
  const [showChangePrimaryColor, setShowChangePrimaryColor] = useState(false);

  const [logoPreview, setLogoPreview] = useState<null|ImagePreviewWizardProps>(null);
  const [bannerPreview, setBannerPreview] = useState<null|ImagePreviewWizardProps>(null);
  const [faviconPreview, setFaviconPreview] = useState<null|ImagePreviewWizardProps>(null);
  const [primaryColor, setPrimaryColor] = useState(DEFAULT_PRIMARY_COLOR);
  const [contrastColor, setContrastColor] = useState(ContrastColor.LIGHT);
  const navigate = useNavigate();

  const changePrimaryColor = (color: ColorResult): void => {
    setPrimaryColor(color.hex);
  }

  const handleVerifyLogo = (file: File|null): Promise<void> => {
    if (actualOperationId == null || file == null) {
      return Promise.resolve();
    }

    return uploadFile(actualOperationId, file, "logo")
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
  }

  const handleUploadLogo = (file: File|null, settings?: CropSettingsProps): Promise<void> => {
    if (actualOperationId == null || file == null) {
      return Promise.resolve();
    }

    return uploadFile(actualOperationId, file, "logo", true, settings)
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
  }

  const handleVerifyBanner = (file: File|null): Promise<void> => {
    if (actualOperationId == null || file == null) {
      return Promise.resolve();
    }

    return uploadFile(actualOperationId, file, "banner")
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
  }

  const handleUploadBanner = (file: File|null, settings?: CropSettingsProps): Promise<void> => {
    if (actualOperationId == null || file == null) {
      return Promise.resolve();
    }

    return uploadFile(actualOperationId, file, "banner", true, settings)
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
  }

  const handleVerifyFavicon = (file: File|null): Promise<void> => {
    if (actualOperationId == null || file == null) {
      return Promise.resolve();
    }

    return uploadFile(actualOperationId, file, "favicon")
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
  }

  const handleUploadFavicon = (file: File|null, settings?: CropSettingsProps): Promise<void> => {
    if (actualOperationId == null || file == null) {
      return Promise.resolve();
    }

    return uploadFile(actualOperationId, file, "favicon", true, settings)
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
  }

  const removeLogo = (): void => {
    if (actualOperationId == null) {
      return;
    }

    deleteLogo(actualOperationId)
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
      })
  }

  const removeBanner = (): void => {
    if (actualOperationId == null) {
      return;
    }    

    deleteBanner(actualOperationId)
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
      })
  }

  const removeFavicon = (): void => {
    if (actualOperationId == null) {
      return;
    }

    deleteFavicon(actualOperationId)
      .then(operation => {
        if (store.operationWizard.operation) {
          operationStoreReducer(STORE, {type: SET_OPERATION, operation: operation})
        } else {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        }
        setError(null);
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
      })
  }

  useEffect(() => {
    if (store.operationWizard.stepToValidate !== OperationWizardSteps.SHOWCASE || store.operationWizard.operation == null) {
      return;
    }

    const body = {
      primaryColor: primaryColor,
      contrastColor: contrastColor === FO_DARK_CONTRAST_COLOR
    }

    setError(null);
    setLoading(true);    

    putOperation(store.operationWizard.operation.id, body)
      .then(operation => {
        setLoading(false);
        operationStoreReducer(STORE, {type: NEXT_STEP, operation: operation});
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
      })
      .finally(() => {
        setStepToValidateWizardStore(STORE, WizardType.CREATE_OPERATION, 0);
        setLoading(false);
      })
  }, [store.operationWizard.stepToValidate])

  useEffect(() => {
    if (loading || propsNoWizard == null || !propsNoWizard.triggerAction || store.global.currentOperation == null) {
      return;
    }

    const body = {
      primaryColor: primaryColor,
      contrastColor: contrastColor === FO_DARK_CONTRAST_COLOR
    }
    setLoading(true);

    putOperation(store.global.currentOperation.id, body)
      .then(operation => {
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        propsNoWizard.setSuccess(t("operations.showcase.save_success"));
      })
      .catch(error => {        
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
      })
      .finally(() => {
        setLoading(false);
        propsNoWizard.setTriggerAction(false);
      })
  }, [propsNoWizard?.triggerAction])

  useEffect(() => {
    const operation = store.operationWizard.currentStep > 1 ? store.operationWizard.operation : store.global.currentOperation;
    if (operation == null) {
      return;
    }

    if (operation.logoUrl && operation.logoOriginalFileName && operation.logoSize) {
      setLogoPreview({
        url: operation.logoUrl,
        name: operation.logoOriginalFileName,
        size: operation.logoSize,
        deleteImage: removeLogo,
        fullWidth: false
      });
    } else {
      setLogoPreview(null);
    }

    if (operation.bannerUrl && operation.bannerOriginalFileName && operation.bannerSize) {
      setBannerPreview({
        url: operation.bannerUrl,
        name: operation.bannerOriginalFileName,
        size: operation.bannerSize,
        deleteImage: removeBanner,
        fullWidth: true
      });
    } else {
      setBannerPreview(null);
    }

    if (operation.faviconUrl && operation.faviconOriginalFileName && operation.faviconSize) {
      setFaviconPreview({
        url: operation.faviconUrl,
        name: operation.faviconOriginalFileName,
        size: operation.faviconSize,
        deleteImage: removeFavicon,
        fullWidth: false,
        smallImage: true
      });
    } else {
      setFaviconPreview(null);
    }

    setPrimaryColor(operation.primaryColor);
    setContrastColor(extractContrastColor(operation.contrastColor));
  }, [store.global.currentOperation, store.operationWizard.operation])

  return (
    <Card sx={{border: "1px solid", borderColor: "ornament.dark", boxShadow: 1, p: 6, ...sx}}>
      {openLogoDialog && <DownloadFileDialog type={FileType.IMAGE} cropType={"logo"} open={openLogoDialog} setOpen={setOpenLogoDialog} preview={logoPreview} name="logo"
        translate={(text: string): string => t("operations.showcase.logo." + text)} handleVerifyFile={handleVerifyLogo} handleUploadFile={handleUploadLogo}/>
      }
      {openBannerDialog && <DownloadFileDialog type={FileType.IMAGE} cropType={"banner"} open={openBannerDialog} setOpen={setOpenBannerDialog} preview={bannerPreview} name="banner"
        translate={(text: string): string => t("operations.showcase.banner." + text)} handleVerifyFile={handleVerifyBanner} handleUploadFile={handleUploadBanner}/>
      }
      {openFaviconDialog && <DownloadFileDialog type={FileType.FAVICON} cropType={"favicon"} open={openFaviconDialog} setOpen={setOpenFaviconDialog} preview={faviconPreview} name="favicon"
        translate={(text: string): string => t("operations.showcase.favicon." + text)} handleVerifyFile={handleVerifyFavicon} handleUploadFile={handleUploadFavicon}/>
      }
      {/** LOGO */}
      <Typography variant="body1" sx={{fontWeight: "bold", mb: 0}}>
        {t("operations.showcase.logo.title")}
      </Typography>
      <Typography variant="body2" color="neutral.main" sx={{mb: 2, fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: t("operations.showcase.logo.description")}} />
      {logoPreview ? <ImagePreviewWizard sx={{mb: 4, ".uploaded-image": {height: "204px", width: "204px !important", maxHeight: "unset", objectFit: "contain", padding: "10px"}}} {...logoPreview}/> :
        <Box sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.main", alignItems: "center", p: 3, mb: 4}}>
          <SecondarySmallButton backgroundColor="ornament.light" label={t("operations.showcase.logo.load_file")}
            icon={PhotoIcon} position={IconPosition.LEFT} disabled={loading}
            action={(): void => setOpenLogoDialog(true)}/>
          <Typography variant="caption" sx={{color: "neutral.main", mt: 0}}>{t("operations.showcase.logo.size")}</Typography>
        </Box>}
      {/** BANNER */}
      <Typography variant="body1" sx={{fontWeight: "bold", mb: 0, pt: 4}}>
        {t("operations.showcase.banner.title")}
      </Typography>
      <Typography variant="body2" color="neutral.main" sx={{mb: 2, fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: t("operations.showcase.banner.description")}} />
      {bannerPreview ? <ImagePreviewWizard sx={{mb: 4}} {...bannerPreview}/> :
        <Box sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.main", alignItems: "center", p: 3, mb: 4}}>
          <SecondarySmallButton backgroundColor="ornament.light" label={t("operations.showcase.banner.load_file")}
            icon={PhotoIcon} position={IconPosition.LEFT} disabled={loading}
            action={(): void => setOpenBannerDialog(true)}/>
          <Typography variant="caption" sx={{color: "neutral.main", mt: 0}}>{t("operations.showcase.banner.size")}</Typography>
        </Box>}
      {/** FAVICON */}
      <Typography variant="body1" sx={{fontWeight: "bold", mb: 0, pt: 4}}>
        {t("operations.showcase.favicon.title")}
      </Typography>
      <Typography variant="body2" color="neutral.main" sx={{mb: 2, fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: t("operations.showcase.favicon.description")}} />
      {faviconPreview ? <ImagePreviewWizard sx={{mb: 4, ".uploaded-image": {height: "32px", width: "32px", objectFit: "none"}}} {...faviconPreview}/> :
        <Box sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.main", alignItems: "center", p: 3, mb: 4}}>
          <SecondarySmallButton backgroundColor="ornament.light" label={t("operations.showcase.favicon.load_file")}
            icon={PhotoIcon} position={IconPosition.LEFT} disabled={loading}
            action={(): void => setOpenFaviconDialog(true)}/>
          <Typography variant="caption" sx={{color: "neutral.main", mt: 0}}>{t("operations.showcase.favicon.size")}</Typography>
        </Box>}
      {/** COLORS */}
      <Typography variant="body1" sx={{fontWeight: "bold", mb: 2, pt: 4}}>
        {t("operations.showcase.colors.title")}
      </Typography>
      <Box sx={{display: "flex"}}>
        <Box sx={{display: "flex", flexDirection: "column", minWidth: "28%"}}>
          <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium", mb: 0}}>
            {t("operations.showcase.colors.primary")}
          </Typography>
          {showChangePrimaryColor && <OperationColorPicker anchorId="primary-color-picker" color={primaryColor} changeColor={changePrimaryColor}
            showChangeColor={showChangePrimaryColor} setShowChangeColor={setShowChangePrimaryColor}/>}
          <Box id="primary-color-picker" sx={{display: "flex", p: "6px", backgroundColor: "ornament.main", borderRadius: "4px", mb: 3, cursor: "pointer"}}
            onClick={(): void => { if (!loading) setShowChangePrimaryColor(!showChangePrimaryColor) }}>
            <Avatar variant="square" sx={{width: 22, height: 22, mr: 1, backgroundColor: primaryColor}}> </Avatar>
            <Typography variant="body2" sx={{fontWeight: "medium"}}>
              {primaryColor}
            </Typography>
          </Box>
          <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium", mb: 0}}>
            {t("operations.showcase.colors.secondary")}
          </Typography>
          <OperationColorSwitch selected={contrastColor} setSelected={setContrastColor} disabled={loading}/>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.main", borderRadius: "4px", width: "100%", ml: 3, px: 3, pt: 3}}>
          <Typography variant="caption" color="neutral.main">
            {t("operations.showcase.colors.example.title")}
          </Typography>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
            <PrimaryBigButton sx={{
              backgroundColor: primaryColor,
              color: contrastColor,
              cursor: "initial",
              ":hover": {backgroundColor: primaryColor, color: contrastColor, boxShadow: 0}
            }} label={t("operations.showcase.colors.example.render")}/>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
