import {Box, Chip, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  getOperation,
  getOperationParticipant,
  getOperationParticipants,
  getOperationParticipantToken,
  sendEmailInvitationsToParticipants,
  setSavDateParticipant,
  updateBlockOrderToParticipants
} from "../../../services/OperationService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Loader} from "../../../components/loader/loader";
import {DataGridPro, GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {GridColumns, GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {frFR, GridFilterModel} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from '@mui/icons-material/Send';
import emptyParticipants from '../../../assets/lists/empty_participants.svg';
import emptySearch from '../../../assets/lists/empty_search.svg';
import {LIBERTY_THEME} from "../../../tokens/libertyTheme";
import {
  changeParticipantPassword,
  getAllParticipantAttributes,
  sendParticipantsMoney
} from "../../../services/ParticipantService";
import {GridColumnVisibilityModel} from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {operationUrl, PRIVATE_URL} from "../../../common/struct/urlManager";
import {
  TInvitationStatus,
  TOperationParticipant,
  TOperationParticipantForListing
} from "../../../interfaces/operationParticipant";
import {TParticipantAttribute, TParticipantAttributeType} from "../../../interfaces/participantAttribute";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {PointsUnit} from "../../../patterns/list/euroPointToggle";
import ListToolbar from "./listToolbar";
import {getExportParticipants} from "../../../services/ImportService";
import {AppContext} from "../../../App";
import {StoreContext} from "../../../common/struct/store";
import ListColumnMenu from "../../../patterns/list/listColumnMenu";
import EmptyList from "../../../patterns/list/emptyList";
import {
  actionColumn,
  ChipCellProps,
  ColumnType,
  enumFilter,
  enumFilterWithoutNull,
  filterOperators,
  gridAction,
  render,
  valueGetter
} from "../../../patterns/list/listUtils";
import ListPagination from "../../../patterns/list/listPagination";
import {globalStoreReducer} from "../../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION, INIT_STORE, UPDATE_ALL_WALLETS} from "../../../common/methods/context-setter/globals";
import Panel, {PanelSize} from "../../../patterns/panel/panel";
import ListParticipantTransactions from "./transactions/listParticipantTransactions";
import ListDeletedOperationParticipants from "./deleted/listDeletedOperationParticipants";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import {getAdminId, isSuperAdmin} from "../../../common/struct/globalVar";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockIcon from "@mui/icons-material/Lock";
import InviteParticipant from "./actions/inviteParticipant";
import AddPointsToParticipant from "./actions/addPointsToParticipant";
import RemovePointsToParticipant from "./actions/removePointsToParticipant";
import GiveSavAccessToParticipant from "./actions/giveSavAccessToParticipant";
import ConnectAsParticipant from "./actions/connectAsParticipant";
import ChangeParticipantPassword from "./actions/changeParticipantPassword";
import DeleteDialog from "../../../patterns/dialog/deleteDialog";
import BlockOrderToSelectedParticipants from "./actions/blockOrderToSelectedParticipants";
import {deleteOperationParticipants} from "../../../services/operationParticipantService";
import {errorManager, manageStringError, RequestFilter, RequestOrder} from "../../../common/methods/ApiService";
import {updateAdminColumnOrder, updateAdminColumnSatus} from "../../../services/AdminService";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {formatFilters, formatIds, formatOrders} from "../../../common/methods/utils";
import {CivilityType} from "../../../interfaces/user";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {participantStoreReducer} from "../../../common/methods/context-setter/participantStoreReducer";
import {getExportDocument} from "../../../common/methods/exportDocument";
import {TOperation} from "../../../interfaces/operation";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import {getCompany} from "../../../services/CompanyService";

interface ListOperationParticipantsProps {
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
  reload: boolean;
  setReload: (reload: boolean) => void;
  participantUpdate: (participant: TOperationParticipant|null) => void;
  operation: TOperation;
}

export default function ListOperationParticipants(props: ListOperationParticipantsProps): JSX.Element {
  const {addSuccess, addError, reload, setReload, participantUpdate, operation} = props;
  const {operationId} = useParams<string>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  useEffect(() => {
    document.title = t("tab_title.operation.participant.list");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [loading, setLoading] = useState<boolean>(false);

  const [loadingParticipants, setLoadingParticipants] = useState(true);
  const [loadingAttributes, setLoadingAttributes] = useState(true);
  const [operationParticipantCountWithFilters, setOperationParticipantCountWithFilters] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const [operationParticipantTotal, setOperationParticipantTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [maxItems, setMaxItems] = useState(25);
  const [search, setSearch] = useState("");
  const [sortingModel, setSortingModel] = useState<GridSortModel>([{field: 'createdAt', sort: 'desc'}]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({items: []});
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>(store.global.admin?.participantColumnsStatus??{});
  const [columnOrder, setColumnOrder] = useState<string[]>(store.global.admin?.participantColumnsOrder??[]);
  const [selectedUnit, setSelectedUnit] = useState(PointsUnit.POINTS);
  const [columns, setColumns] = useState<GridColumns>([]);
  const [attributes, setAttributes] = useState<TParticipantAttribute[]>([]);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<null|HTMLElement>(null);
  const [openColumnsDialog, setOpenColumnsDialog] = useState(false);
  const [operationParticipant, setOperationParticipant] = useState<TOperationParticipant|null>(null);
  const [reset, setReset] = useState(false);
  const [openTransactionPanel, setOpenTransactionPanel] = useState(false);
  const [openDeletedParticipantsPanel, setOpenDeletedParticipantsPanel] = useState(false);
  const [openInviteParticipant, setOpenInviteParticipant] = useState(false);
  const [openAddPointsToParticipant, setOpenAddPointsToParticipant] = useState(false);
  const [openRemovePointsToParticipant, setOpenRemovePointsToParticipant] = useState(false);
  const [openGiveSavAccessToParticipant, setOpenGiveSavAccessToParticipant] = useState(false);
  const [openConnectAsParticipant, setOpenConnectAsParticipant] = useState(false);
  const [openUpdatePasswordParticipant, setOpenUpdatePasswordParticipant] = useState(false);
  const [openBlockOrderParticipant, setOpenBlockOrderParticipant] = useState(false);
  const [openDeleteParticipant, setOpenDeleteParticipant] = useState(false);
  const [operationParticipantToken, setOperationParticipantToken] = useState<string|null>(null);
  const [newWallet, setNewWallet] = useState<string|null>(null);

  const closeTransactionPanel = (): void => setOpenTransactionPanel(false)
  const closeDeletedParticipantsPanel = (): void => setOpenDeletedParticipantsPanel(false)
  const closeInviteParticipant = (): void => setOpenInviteParticipant(false)
  const closeAddPointsToParticipant = (): void => setOpenAddPointsToParticipant(false)
  const closeRemovePointsToParticipant = (): void => setOpenRemovePointsToParticipant(false)
  const closeGiveSavAccessToParticipant = (): void => setOpenGiveSavAccessToParticipant(false)
  const closeConnectAsParticipant = (): void => setOpenConnectAsParticipant(false)
  const closeUpdatePasswordParticipant = (): void => setOpenUpdatePasswordParticipant(false)
  const closeBlockOrderParticipant = (): void => setOpenBlockOrderParticipant(false)
  const closeDeleteParticipant = (): void => setOpenDeleteParticipant(false)

  const loadOperationParticipants = (): Promise<void> => {
    if (filterModel.items.length > 0 && filterModel.items.find(item => item.operatorValue !== "isEmpty" && item.operatorValue !== "isNotEmpty" && item.value == null)) {
      return Promise.resolve();
    }

    setLoadingParticipants(true);
    const filters: RequestFilter[] = formatFilters(filterModel, columns, attributes);
    const sort: RequestOrder = formatOrders(sortingModel) ;

    return getOperationParticipants(operationId!, page + 1, maxItems, search, sort, filters).then(operationParticipants => {
      setOperationParticipantCountWithFilters((operationParticipants.totalItems));
      setRows(operationParticipants.items);
    }).finally(() => setLoadingParticipants(false))
  }

  const columnType = (type: TParticipantAttributeType): ColumnType => {
    switch (type) {
    case TParticipantAttributeType.STRING:
      return ColumnType.STRING;
    case TParticipantAttributeType.NUMBER:
      return ColumnType.NUMBER;
    case TParticipantAttributeType.DATE:
      return ColumnType.DATE;
    }
  }
  const loadParticipantAttributes = (): Promise<{all: GridEnrichedColDef[], attributes: GridEnrichedColDef[]}> => {
    return getAllParticipantAttributes()
      .then(attributes => {
        const attributeColumns = attributes.map(attribute => columnDefinition(attribute.label, columnType(attribute.type), true, true, true));
        initialColumns.push(...attributeColumns);
        setAttributes(attributes);
        setColumns(initialColumns);
        return {all: initialColumns, attributes: attributeColumns};
      })
  }

  const loadOperationParticipantsCount = (): any => {
    return getOperation(operationId!).then((data) => setOperationParticipantTotal(data.participantStats.total));
  }

  const generateInitialVisibilityModel = (columns: GridEnrichedColDef[], attributeColumns: GridEnrichedColDef[]): GridColumnVisibilityModel => {
    const newModel: GridColumnVisibilityModel = {};   
    if (columnOrder.length == 0) {
      newModel["rewardEuros"] = false;
      newModel["spentEuros"] = false;
      newModel["totalEuros"] = false;
      newModel["lastRewardEuros"] = false;
      attributeColumns.forEach(column => {
        newModel[column.field] = false;
      })
    } else {
      columns.forEach(column => {
        if (!columnOrder.includes(column.field)) newModel[column.field] = false;
      })
    }
    return newModel;
  }

  const generateResetInitialVisibilityModel = (): GridColumnVisibilityModel => {
    const newModel: GridColumnVisibilityModel = {};
    newModel["rewardEuros"] = false;
    newModel["spentEuros"] = false;
    newModel["totalEuros"] = false;
    newModel["lastRewardEuros"] = false;
    return newModel
  }

  const generateVisibilityModel = (model: GridColumnVisibilityModel, attributeColumns: GridEnrichedColDef[] = []): GridColumnVisibilityModel => {
    const newModel = structuredClone(model);    

    if (selectedUnit == PointsUnit.POINTS) {      
      newModel["rewardPoints"] = (model["rewardEuros"] == undefined || model["rewardEuros"]) || model["rewardPoints"];
      newModel["spentPoints"] = (model["spentEuros"] == undefined || model["spentEuros"]) || model["spentPoints"];
      newModel["totalPoints"] = (model["totalEuros"] == undefined || model["totalEuros"]) || model["totalPoints"];
      newModel["lastRewardPoints"] = (model["lastRewardEuros"] == undefined || model["lastRewardEuros"]) || model["lastRewardPoints"];

      newModel["rewardEuros"] = false;
      newModel["spentEuros"] = false;
      newModel["totalEuros"] = false;
      newModel["lastRewardEuros"] = false;
    } else {      
      newModel["rewardEuros"] = (model["rewardPoints"] == undefined || model["rewardPoints"]) || model["rewardEuros"];
      newModel["spentEuros"] = (model["spentPoints"] == undefined || model["spentPoints"]) || model["spentEuros"];
      newModel["totalEuros"] = (model["totalPoints"] == undefined || model["totalPoints"]) || model["totalEuros"];
      newModel["lastRewardEuros"] = (model["lastRewardPoints"] == undefined || model["lastRewardPoints"]) || model["lastRewardEuros"];

      newModel["rewardPoints"] = false;
      newModel["spentPoints"] = false;
      newModel["totalPoints"] = false;
      newModel["lastRewardPoints"] = false;
    }

    attributeColumns.forEach(attribute => newModel[attribute.field] = false);
    return newModel;
  }

  const generateVisibleColumn = (): string[] => {
    const newOrder: string[] = [];
    apiRef.current.getAllColumns().forEach(column => {
      const visibleColumn = Object.keys(visibilityModel).find(key => key == column.field);
      if (visibleColumn == undefined || visibilityModel[column.field]) newOrder.push(column.field);
    })   

    return newOrder;
  }

  const updateWallets = (): void => {
    getCompany(store.global.company.id)
      .then(company => {
        if (company) {
          globalStoreReducer(STORE, {type: UPDATE_ALL_WALLETS, wallets: company.wallets});
        }
      })
      .catch(error => manageStringError(error, addError, t))
  }

  useEffect(() => {
    updateWallets();
  }, []);

  useEffect(() => {
    if(reset) {
      loadParticipantAttributes()
        .then(() => setVisibilityModel(generateResetInitialVisibilityModel()))
        .finally(() => setLoadingAttributes(false));
      loadOperationParticipantsCount();
      setReset(false);
    }
  }, [reset])

  useEffect(() => {
    loadParticipantAttributes()
      .then(columns => setVisibilityModel(store.global.admin?.participantColumnsStatus??generateInitialVisibilityModel(columns.all, columns.attributes)))
      .finally(() => setLoadingAttributes(false))
    loadOperationParticipantsCount();
  }, [])

  useEffect(() => {
    if (!loadingAttributes) {
      if (store.participantWizard.currentStep > store.participantWizard.maximumStep) {
        addSuccess(t("wizard.import.success" + (store.participantWizard.saving ? "_saving" : "")));
        participantStoreReducer(STORE, {type: INIT_STORE});
      }
    }
  }, [loadingAttributes])

  useEffect(() => {
    if (!apiRef.current.getAllColumns) {
      return;
    }
    setColumnOrder(generateVisibleColumn())    
    updateAdminColumnSatus(getAdminId()!, visibilityModel) 
  }, [visibilityModel])

  useEffect(() => {    
    updateAdminColumnOrder(getAdminId()!, columnOrder)
  }, [columnOrder])

  useEffect(() => {
    if (apiRef.current.subscribeEvent) {
      apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
        setColumnOrder(generateVisibleColumn())
      })
    }
  }, [apiRef.current])

  useEffect(() => {
    if (reload) {
      loadOperationParticipants()
        .then(() => getOperation(operation.id))
        .then(operation => {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
          return Promise.resolve();
        })
        .finally(() => setReload(false));
    }
  }, [reload])

  useEffect(() => {
    if (apiRef.current.setSelectionModel && apiRef.current.getSelectedRows().size > 0) {
      apiRef.current.setSelectionModel([]);
    }
    loadOperationParticipants()
  }, [page, search, sortingModel, filterModel, maxItems])

  useEffect(() => {
    if (!loadingAttributes) {
      setVisibilityModel((previousModel) => generateVisibilityModel(previousModel));
    }
  }, [selectedUnit])

  /** OPEN ACTION DIALOGS */
  const openParticipantPanel = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      participantUpdate(await getOperationParticipant(operation.id, participant.id));
    } else {
      participantUpdate(null);
    }
  }
  const inviteParticipant = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenInviteParticipant(true);
    }
  }
  const addPointsToParticipant = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenAddPointsToParticipant(true);
    }
  }
  const removePointsToParticipant = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenRemovePointsToParticipant(true);
    }
  }
  const giveSavAccessToParticipant = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenGiveSavAccessToParticipant(true);
    }
  }
  const openParticipantTransactionsPanel = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenTransactionPanel(true);
    }
  }
  const connectWithParticipant = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOperationParticipantToken(await getOperationParticipantToken(participant.id).then((res) => { return res.token }));
      setOpenConnectAsParticipant(true);
    }
  }
  const updateParticipantPassword = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenUpdatePasswordParticipant(true);
    }
  }
  const blockOrderForParticipant = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenBlockOrderParticipant(true);
    }
  }

  const openDeleteParticipantWarning = async (participant?: TOperationParticipantForListing): Promise<void> => {
    if (participant && operation) {
      setNewWallet('/bo/wallets/'+store.global.company.defaultWallet!.id);
      
      setOperationParticipant(await getOperationParticipant(operation.id, participant.id));
      setOpenDeleteParticipant(true);
    }
  }

  /** ACTIONS */
  const deleteParticipant = (): Promise<void> => {
    if (operationParticipant == null) {
      return Promise.resolve();
    }

    const filters = formatIds([operationParticipant.id]);

    return deleteOperationParticipants(operationParticipant.operation.id, search, filters, newWallet!.replace('/bo/wallets/',''))
      .then(() => {
        setReload(true);
        addSuccess(t("operation_participants.actions.delete_success"));
        setNewWallet(null);
        closeDeleteParticipant();
        updateWallets();
      })
      .catch(error => manageStringError(error, addError, t))
  }

  const sendMail = (sendAt: string): void => {
    if (operationParticipant == null) {
      return;
    }

    setLoading(true);

    const filters = formatIds([operationParticipant.id]);

    sendEmailInvitationsToParticipants(operationParticipant.operation.id, search, filters, sendAt)
      .then(() => {
        setReload(true);
        addSuccess(sendAt == "now" ? t("operation_participants.actions.invite_success", {mail: operationParticipant.participant.email}) : t("operation_participants.actions.invite_planned", {mail: operationParticipant.participant.email}));
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), addError, t);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const sendMoneyToParticipant = (amount: number, wallet: string): void => {
    if (operationParticipant == null) {
      return;
    }

    const filters = formatIds([operationParticipant.id]);

    sendParticipantsMoney(amount.toString(), wallet, operationParticipant.operation.id, null, filters)
      .then(() => {
        setReload(true);
        addSuccess(t("operation_participants.actions.add_points_success", {name: participantName(operationParticipant), euroValue: amount}));
        closeAddPointsToParticipant();
        updateWallets();
      })
      .catch(error => manageStringError(error, addError, t))
  }

  const removeMoneyToParticipant = (amount: number, wallet: string): void => {
    if (operationParticipant == null) {
      return;
    }

    const filters = formatIds([operationParticipant.id]);

    sendParticipantsMoney(amount.toString(), wallet, operationParticipant.operation.id, null, filters)
      .then(reward => {
        setReload(true);
        if (reward) addSuccess(t("operation_participants.actions.remove_points_success", {name: participantName(operationParticipant), euroValue: amount}));
        else addSuccess(t("operation_participants.actions.remove_points_success_none", {name: participantName(operationParticipant)}));
        closeRemovePointsToParticipant();
        updateWallets();
      })
      .catch(error => manageStringError(error, addError, t))
  }

  const setSavDateToParticipant = (dateSav: string): void => {
    if (operationParticipant == null) {
      return;
    }

    setSavDateParticipant(operationParticipant.id, dateSav)
      .then(() => {
        setReload(true);
        addSuccess(t("operation_participants.actions.give_sav_success"));
        closeGiveSavAccessToParticipant();
      })
      .catch(error => manageStringError(error, addError, t))
  }

  const changePassword = (password: string): Promise<void> => {
    if (operationParticipant == null) {
      return Promise.resolve();
    }

    return changeParticipantPassword(operationParticipant.participant.id, password)
      .then(() => {
        setReload(true);
        addSuccess(t("operation_participants.actions.update_password_success", {name: participantName(operationParticipant)}));
        closeUpdatePasswordParticipant();
      })
      .catch(error => manageStringError(error, addError, t))
  }
  const updateBlockOrderParticipant = (blockOrder: boolean): void => {
    if (operationParticipant == null) {
      return;
    }

    const filters = formatIds([operationParticipant.id]);

    updateBlockOrderToParticipants(operationParticipant.operation.id, blockOrder, search, filters)
      .then(() => {
        setReload(true);
        if(blockOrder) {
          addSuccess(t('operation_participants.actions.block.success'))
        }else{
          addSuccess(t('operation_participants.actions.delock.success'))
        }
        closeBlockOrderParticipant();
      })
      .catch(error => manageStringError(error, addError, t))
  }
  /** LIST DEFINITION */
  const participantName = (operationParticipant: TOperationParticipant): string => {
    const names = [operationParticipant.participant.firstName??'', operationParticipant.participant.lastName??''];
    return names.join(' ');
  }

  const columnDefinition = (field: string, type: ColumnType = ColumnType.STRING, fromAttributes = false, sortable = true, isNullable = false, width?: number): GridEnrichedColDef => {
    return {
      field: field,
      type: (type == ColumnType.POINTS || type == ColumnType.EUROS) ? ColumnType.NUMBER : type,
      align: "left",
      headerAlign: "left",
      headerName: fromAttributes ? field : t(`operation_participants.columns.${field}`),
      sortable: fromAttributes ? true : sortable,
      hideable: true,
      filterable: sortable,
      filterOperators: filterOperators(type, isNullable),
      pinnable: false,
      valueGetter: (item: GridValueGetterParams): string => valueGetter(fromAttributes ? item.row.attributeValues[field] : item.row[field], type),
      renderCell: (item): JSX.Element => render(item.value, type, t),
      renderHeader: (): JSX.Element => <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold"}}>
        {fromAttributes ? field : t(`operation_participants.columns.${field}`)}
      </Typography>,
      width: width ?? field === "email" ? 225 : 170
    }
  }

  const invitationProps = (row: TOperationParticipant): ChipCellProps => {
    const status: TInvitationStatus = row.invitationStatus;
    switch (status) {
    case TInvitationStatus.UNSENT:
      return {
        icon: <CancelScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.light",
        color: "neutral.main",
        backgroundColor: "ornament.main"
      }
    case TInvitationStatus.SENT:
      return {
        icon: <SendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light",
        date: row.invitationEmailDate ? new Date(row.invitationEmailDate) : "-"
      }
    case TInvitationStatus.PENDING:
      return {
        icon: <ScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "info.main",
        color: "info.dark",
        backgroundColor: "infoLight.light",
        date: row.invitationEmailDate ? new Date(row.invitationEmailDate) : "-"
      }
    case TInvitationStatus.ERROR:
      return {
        icon: <CancelScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.light",
        color: "neutral.main",
        backgroundColor: "ornament.main",
        date: row.lastConnectionDate ? new Date(row.lastConnectionDate) : "-"
      }
    }
  }

  const blockOrderProps = (row: TOperationParticipant): ChipCellProps => {
    const isBlock = row.blockOrder;
    if (isBlock) {
      return {
        icon: <NotInterestedIcon sx={{width: 18, height: 18}}/>,
        iconColor: "warning.main",
        color: "warning.dark",
        backgroundColor: "warning.light",
      }
    } else {
      return {
        icon: <CheckCircleOutlineIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light",
      }
    }
  }


  const invitationColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition('invitationStatus'),
      type: "singleSelect",
      width: 225,
      filterOperators: enumFilter,
      valueOptions: Object.keys(TInvitationStatus).map(status => ({value: status, label: t(`operation_participants.invitation_status.${status.toLowerCase()}_label`)})),
      renderCell: (item): JSX.Element => {
        const invitation = invitationProps(item.row);
        return (<Link to={PRIVATE_URL.OPERATION_EMAIL_HISTORY}><Chip icon={invitation.icon}
          sx={{backgroundColor: invitation.backgroundColor, py: 0, pl: "7px", pr: "0px", cursor: "pointer", "& .MuiChip-icon": {color: invitation.iconColor}}}
          label={<Typography variant="body2" color={invitation.color} sx={{fontWeight: "regular"}}>
            {t("operation_participants.invitation_status." + item.value.toLowerCase(), {date: invitation.date})}
          </Typography>}/></Link>)
      }
    }
  }

  const civilityColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition("civility", ColumnType.STRING),
      type: "singleSelect",
      width: 100,
      filterOperators: enumFilter,
      valueOptions: Object.keys(CivilityType).map(civility => ({value: civility, label: t(`participants.participant_form.civility.${civility}`)})),
      valueGetter: (item: GridValueGetterParams): string => {
        return item.row.civility ? t('participants.participant_form.civility.' + (item.row.civility)) : "-"
      }
    }
  }

  const blockOrderColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition('blockOrder'),
      type: "singleSelect",
      width: 150,
      filterOperators: enumFilterWithoutNull,
      valueOptions: [{value: true, label: t('participants.participant_form.blockOrder.disable')}, {value: false, label: t('participants.participant_form.blockOrder.enabled')}],
      renderCell: (item): JSX.Element => {
        const blockOrder = blockOrderProps(item.row);
        return <Chip icon={blockOrder.icon} sx={{backgroundColor: blockOrder.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: blockOrder.iconColor, ml:2}}}
        />

      }
    }
  }

  const initialColumns = [
    civilityColumnDefinition(),
    columnDefinition("lastName", ColumnType.STRING, false, true, true),
    columnDefinition("firstName", ColumnType.STRING, false, true, true),
    columnDefinition("email", ColumnType.STRING, false, true),
    columnDefinition("phone", ColumnType.STRING),
    invitationColumnDefinition(),
    columnDefinition("rewardEuros", ColumnType.EUROS),
    columnDefinition("spentEuros", ColumnType.EUROS),
    columnDefinition("totalEuros", ColumnType.EUROS, false, false),
    columnDefinition("lastRewardEuros", ColumnType.EUROS, false, false, true, 250),
    columnDefinition("rewardPoints", ColumnType.POINTS),
    columnDefinition("spentPoints", ColumnType.POINTS),
    columnDefinition("totalPoints", ColumnType.POINTS, false, false),
    columnDefinition("lastRewardPoints", ColumnType.POINTS, false, false, true, 250),
    columnDefinition("lastRewardDate", ColumnType.DATE, false, false, true, 250),
    columnDefinition("createdAt", ColumnType.DATE),
    columnDefinition("invitationEmailDate", ColumnType.DATE, false, true, true),
    columnDefinition("lastConnectionDate", ColumnType.DATE, false, true, true),
    blockOrderColumnDefinition(),
    actionColumn(params => [
      gridAction(params, "update", t("operation_participants.actions.update"), EditIcon, openParticipantPanel),
      gridAction(params, "invite", t("operation_participants.actions.invite"), EmailIcon, inviteParticipant, true),
      gridAction(params, "add_points", t("operation_participants.actions.add_points"), AddIcon, addPointsToParticipant),
      gridAction(params, "remove_points", t("operation_participants.actions.remove_points"), RemoveIcon, removePointsToParticipant, true),
      gridAction(params, "transactions", t("operation_participants.actions.transactions"), ShoppingBasketIcon, openParticipantTransactionsPanel),
      ...(isSuperAdmin() ? [gridAction(params, "give_sav", t("operation_participants.actions.give_sav"), SupportAgentIcon, giveSavAccessToParticipant)] : []),
      gridAction(params, "connect", t("operation_participants.actions.connect"), VpnKeyIcon, connectWithParticipant),
      gridAction(params, "update_password", t("operation_participants.actions.update_password"), LockIcon, updateParticipantPassword, true),
      gridAction(params, "block_order",params.row.blockOrder ? t("operation_participants.actions.delock_order"): t("operation_participants.actions.block_order"),params.row.blockOrder ? CheckCircleOutlineIcon: NotInterestedIcon, blockOrderForParticipant, true),
      gridAction(params, "delete", t("operation_participants.actions.delete"), DeleteIcon, openDeleteParticipantWarning),
    ])
  ]

  /** TOOLBAR **/
  const exportParticipants = (): Promise<void> => {
    return getExportParticipants(operationId!, search, formatOrders(sortingModel), formatFilters(filterModel, columns, attributes), visibilityModel).then(response => getExportDocument(response));
  }
  const navigateToImport = (): void => navigate(operationUrl(PRIVATE_URL.IMPORT_OPERATION_PARTICIPANTS, operationId!))

  return (
    <Box sx={{display: "flex", flex: 1, flexDirection: "column"}}>
      {openTransactionPanel && operationParticipant && <Panel key="transactions-participant" size={PanelSize.BIG} title={t("operation_participants.transactions.title", {name: participantName(operationParticipant)})} open={openTransactionPanel} close={closeTransactionPanel}><ListParticipantTransactions operationParticipantId={operationParticipant.id}/></Panel>}
      {openDeletedParticipantsPanel && <Panel title={t("operation_participants.deleted.title")} size={PanelSize.BIG} open={openDeletedParticipantsPanel} close={closeDeletedParticipantsPanel}><ListDeletedOperationParticipants setReloadParticipants={setReload}/></Panel>}
      {openInviteParticipant && operationParticipant && <InviteParticipant key="invite-participant" openDialog={openInviteParticipant} closeDialog={closeInviteParticipant} sendMail={sendMail} operationParticipant={operationParticipant} loading={loading}/>}
      {openAddPointsToParticipant && operationParticipant && <AddPointsToParticipant key="add-points-participant" openDialog={openAddPointsToParticipant} closeDialog={closeAddPointsToParticipant} sendMoney={sendMoneyToParticipant} operationParticipant={operationParticipant} operation={operation}/>}
      {openRemovePointsToParticipant && operationParticipant && <RemovePointsToParticipant key="remove-points-participant" openDialog={openRemovePointsToParticipant} closeDialog={closeRemovePointsToParticipant} removeMoney={removeMoneyToParticipant} operationParticipant={operationParticipant} operation={operation}/>}
      {openGiveSavAccessToParticipant && operationParticipant && isSuperAdmin() && <GiveSavAccessToParticipant key="give-sav-access-participant" openDialog={openGiveSavAccessToParticipant} closeDialog={closeGiveSavAccessToParticipant} setSavDate={setSavDateToParticipant} operationParticipant={operationParticipant}/>}
      {openConnectAsParticipant && operationParticipant && <ConnectAsParticipant key="connect-participant" openDialog={openConnectAsParticipant} closeDialog={closeConnectAsParticipant} operationParticipant={operationParticipant} operation={operation} operationParticipantToken={operationParticipantToken}/>}
      {openUpdatePasswordParticipant && operationParticipant && <ChangeParticipantPassword key="change-password-participant" openDialog={openUpdatePasswordParticipant} closeDialog={closeUpdatePasswordParticipant} changePassword={changePassword} participant={operationParticipant.participant}/>}
      {openBlockOrderParticipant && operationParticipant && !operationParticipant.blockOrder && <BlockOrderToSelectedParticipants key="block-order-participant" open={openBlockOrderParticipant} close={closeBlockOrderParticipant} title={t("operation_participants.actions.block.title", {name: participantName(operationParticipant)})} content1={t("operation_participants.actions.block.content_1")} content2={t("operation_participants.actions.block.content_2")} button={t("operation_participants.actions.block.confirm")} block={true} setBlockOrder={updateBlockOrderParticipant}/>}
      {openBlockOrderParticipant && operationParticipant && operationParticipant.blockOrder && <BlockOrderToSelectedParticipants key="delock-order-participant" open={openBlockOrderParticipant} close={closeBlockOrderParticipant} title={t("operation_participants.actions.delock.title", {name: participantName(operationParticipant)})} content1={t("operation_participants.actions.delock.content_1")} content2={t("operation_participants.actions.delock.content_2")} button={t("operation_participants.actions.delock.confirm")} block={false} setBlockOrder={updateBlockOrderParticipant}/>}
      {openDeleteParticipant && operationParticipant && <DeleteDialog key="delete-participant" open={openDeleteParticipant} close={closeDeleteParticipant} title={t("operation_participants.actions.delete_title", {name: participantName(operationParticipant)})} warning={t("operation_participants.actions.delete_warning")} deleteElement={deleteParticipant} walletSelection={store.global.company.wallets.length > 2 ? true : false} excludedWallets={[store.global.company.expiredPointsWallet!.id]} newWallet={newWallet} setNewWallet={setNewWallet}/>}
      {loadingAttributes ? <Loader height={"100%"} /> :
        <DataGridPro apiRef={apiRef}
          sx={{overflowX: "auto", backgroundColor: "ornament.light", borderColor: "ornament.dark",
            "& .MuiDataGrid-columnSeparator": {color: "neutral.light"},
            "& .MuiDataGrid-columnHeader:first-of-type": {"& .MuiDataGrid-columnSeparator": {display: "none"}},
            "& .MuiDataGrid-columnHeader:last-of-type": {"& .MuiDataGrid-columnSeparator": {display: "none"}},
            "& .MuiDataGrid-columnHeaderTitleContainer": {ml: 2},
            "& .MuiDataGrid-columnHeaderTitleContainer:nth-of-type(1)": {ml: "unset"},
            "& .MuiSvgIcon-root": {fontSize: 24},
            "& .MuiDataGrid-footerContainer": {backgroundColor: "ornament.main", minHeight: "unset"},
            "& .MuiDataGrid-pinnedColumns": {boxShadow: 4},
            "& .MuiDataGrid-pinnedColumnHeaders": {boxShadow: 4},
            "& .MuiDataGrid-selectedRowCount": {visibility: "hidden"},
            // Le thème ici n'est pas compris, d'où le passage par la valeur directement de la couleur
            "& .MuiDataGrid-row.Mui-hovered": {backgroundColor: LIBERTY_THEME.palette.ornament.main},
            "& .MuiDataGrid-row.Mui-selected": {backgroundColor: LIBERTY_THEME.palette.primaryLight.light},
            "& .MuiDataGrid-actionsCell": {"& button": {borderRadius: "4px", ":hover": {backgroundColor: "neutral.light"}}},
            "& .MuiDataGrid-pinnedColumns .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {outline: "none"},
            "& .MuiDataGrid-pinnedColumns .MuiDataGrid-row--lastVisible .MuiDataGrid-actionsCell button:hover": {backgroundColor: "ornament.main"}
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          loading={loadingParticipants}
          pagination
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          page={page}
          pageSize={maxItems}
          onPageSizeChange={(newSize: number): void => setMaxItems(newSize)}
          sortModel={sortingModel}
          onSortModelChange={(model: GridSortModel): void => { setPage(0); setSortingModel(model) }}
          filterModel={filterModel}
          onFilterModelChange={(model): void => { setPage(0); setFilterModel(model) }}
          columnVisibilityModel={visibilityModel}
          onColumnVisibilityModelChange={(model: GridColumnVisibilityModel): void => setVisibilityModel(model)}
          columns={columns}
          rows={rows}
          rowCount={operationParticipantTotal}
          rowsPerPageOptions={[25,50,100]}
          rowHeight={54}
          headerHeight={90}
          initialState={{
            pinnedColumns: {right: ['actions']},
            pagination: {pageSize: 25},
            columns: {
              orderedFields: columnOrder
            }
          }}
          components={{
            Toolbar: ListToolbar,
            Pagination: ListPagination,
            NoRowsOverlay: EmptyList,
            ColumnMenu: ListColumnMenu,
            FilterPanelDeleteIcon: (): JSX.Element => <DeleteIcon sx={{color: "neutral.main"}}/>,
            ColumnMenuIcon: DragIndicatorIcon
          }}
          componentsProps={{
            panel: {
              anchorEl: filtersAnchorEl,
              sx: {position: "fixed !important", "& .MuiDataGrid-paper": {mt: "18px", mr: 7}}
            },
            toolbar: {
              disableFilters: rows.length === 0 && filterModel.items.length === 0,
              search,
              setSearch,
              selectedUnit,
              setSelectedUnit,
              openColumnsDialog,
              setOpenColumnsDialog,
              setOpenDeletedParticipantsPanel,
              attributes,
              apiRef,
              filterModel,
              setFiltersAnchorEl,
              exportParticipants,
              setReload,
              operation,
              addSuccess,
              addError,
              setReset,
              setPage,
              total: operationParticipantCountWithFilters,
              openParticipantPanel
            },
            pagination: {
              page,
              setPage,
              total: operationParticipantCountWithFilters,
              maxItems,
              setMaxItems
            },
            noRowsOverlay: {
              label: (search.length > 0 || filterModel.items.length) > 0 ? t("list.empty_search") : t("operation_participants.empty_list"),
              image: (search.length > 0 || filterModel.items.length) > 0 ? emptySearch : emptyParticipants,
              action: (search.length > 0 || filterModel.items.length) > 0 ? undefined : {
                label: t("operation_participants.empty_action"),
                fun: navigateToImport
              }
            },
            filterPanel: {
              sx: {
                border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3,
                "& .MuiDataGrid-panelFooter": {px: 3, pb: 3, pt: "0px"},
                "& .MuiButton-root": {color: "neutral.main"}
              },
              filterFormProps: {
                sx: {
                  alignItems: "center",
                  p: 3,
                  "& legend": {pr: 1},
                  "& .MuiDataGrid-filterFormLinkOperatorInput": {display: "none", ml: 3, mr: "0px", "& legend": {pr: "0px"}},
                  "& .MuiDataGrid-filterFormColumnInput": {ml: 3},
                  "& .MuiDataGrid-filterFormOperatorInput": {ml: 3},
                  "& .MuiDataGrid-filterFormValueInput": {ml: 3}
                },
                deleteIconProps: {
                  sx: {
                    m: "0px"
                  }
                },
                linkOperatorInputProps: {
                  variant: "outlined"
                },
                columnInputProps: {
                  variant: "outlined"
                },
                operatorInputProps: {
                  variant: "outlined"
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: "outlined"
                  }
                }
              }
            }
          }}
          checkboxSelection
          disableSelectionOnClick
        />}
    </Box>
  )
}
