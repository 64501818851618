import {Box, Card, Typography} from "@mui/material";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import React, {useContext, useEffect, useState} from "react";
import SecondLevelMenu from "../../patterns/menu/secondLevelMenu";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SecondaryBigButton,} from "../../components/buttons/mainButton";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import {IconPosition} from "../../components/buttons/buttonProps";
import OperationBand from "./operationBand";
import OperationCustomisation from "./operationCustomisation";
import OperationDashboard from "./operationDashboard";
import {AppContext} from "../../App";
import {OperationInformation, StoreContext} from "../../common/struct/store";
import {TOperation} from "../../interfaces/operation";
import ErrorLogo from "../../assets/error.svg";
import {Loader} from "../../components/loader/loader";
import {ACTIONS, CallBackProps, EVENTS} from "react-joyride";
import ProductTour, {ProductTourStep,} from "../../patterns/productTour/productTour";
import Footer from "../../patterns/footer/footer";
import {getOperation} from "../../services/OperationService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {RequestFilter, RequestFilterOperator} from '../../common/methods/ApiService';
import {TWalletTransactionStatus} from "../../interfaces/wallet"
import {AccessToShopButton} from "./customisation/accessToShopButton";
import {getCompanyWalletTransactionsCount} from "../../services/WalletTransactionService";

export enum OperationMenu {
  DASHBOARD,
  CUSTOMISATION,
}

export default function OperationMainPage(): JSX.Element {
  const {t} = useTranslation();
  const {operationId} = useParams<string>();

  const location = useLocation();
  const extractSelectedMenu = (): OperationMenu => {
    return location.pathname ===
    operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, operationId!)
      ? OperationMenu.CUSTOMISATION
      : OperationMenu.DASHBOARD;
  };

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const operations = store.global.operations;
  const company = store.global.company;

  const [loading, setLoading] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());
  const [operation, setOperation] = useState<TOperation | undefined>(undefined);
  const [ongoingTransactionsCount, setOngoingTransactionsCount] = useState<number|null>(null);
  const [triggerProductTour, setTriggerProductTour] = useState<OperationMenu|null>(null);
  const [index, setIndex] = useState(-1);
  const [stepIndex, setStepIndex] = useState(0);
  const steps: ProductTourStep[] = [
    {
      target: "#operation-list",
      name: "operation_list",
      placement: "right-start",
      placementBeacon: "top",
    },
    {
      target: "#sidebar-menu",
      name: "sidebar-menu",
      placement: "right-start",
      placementBeacon: "top",
    },
    {
      target: "#menu-parameters",
      name: "parameters",
      placement: "bottom-start",
      placementBeacon: "left",
    },
    {
      target: "#menu-dashboard",
      name: "dashboard",
      placement: "bottom-start",
      placementBeacon: "left",
    },
    {
      target: "#operation-band",
      name: "operation_band",
      placement: "bottom-start",
      placementBeacon: "left",
    },
    {
      target: "#operation-participants",
      name: "participants",
      placement: "bottom-start",
      placementBeacon: "top",
    },
    {
      target: "#operation-shop",
      name: "shop",
      placement: "right-start",
      placementBeacon: "top",
    },
    {
      target: "#operation-vitrine",
      name: "customisation",
      placement: "right-start",
      placementBeacon: "top",
    },
    {
      target: "#operation-emails",
      name: "email",
      placement: "right-start",
      placementBeacon: "top",
    },
    {
      target: "#operation-edito",
      name: "edito",
      placement: "right-start",
      placementBeacon: "top",
    },
    {
      target: "#operation-rules",
      name: "rules",
      placement: "right-start",
      placementBeacon: "top",
    },
    {
      target: "#show-vitrine",
      name: "vitrine",
      placement: "bottom-end",
      placementBeacon: "right",
    },
  ];

  const reloadOperation = (): void => {
    setLoading(true);
    setIndex(operations.indexOf(
      operations.find((o: OperationInformation) => o.id == operationId) ??
      operations[0]
    ));
    getOperation(operationId!)
      .then(operation => {        
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        setOperation(operation);
      })
      .catch(() => setOperation(store.global.currentOperation))
      .finally(() => setLoading(false))
  }

  useEffect(() => {        
    if (store.global.currentOperation?.id === operationId && operation == undefined) {
      reloadOperation();
    }
  }, [store]);

  useEffect(() => {
    reloadOperation();
    setSelectedMenu(extractSelectedMenu());
  }, [location]);

  const filter: RequestFilter[] = [{field: 'status', items: [{value: TWalletTransactionStatus.ON_GOING, operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]}];
  useEffect(() => {
    if (company) {
      getCompanyWalletTransactionsCount(company.id, filter)
        .then((res) => { setOngoingTransactionsCount(res.totalItems) });
    }
  }, [company])

  const menus = [
    {
      label: "operations.menu.customisation",
      url: operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, operationId!),
      id: "menu-parameters",
    },
    {
      label: "operations.menu.dashboard",
      url: operationUrl(PRIVATE_URL.OPERATION_DASHBOARD, operationId!),
      id: "menu-dashboard",
    },
  ];

  const handleProductTour = (data: CallBackProps): void => {
    if (
      data.status == "skipped" ||
      data.status == "finished" ||
      data.action == "close"
    ) {
      setTriggerProductTour(null);
      setStepIndex(0);
    }
    if (data.type == EVENTS.STEP_AFTER) {
      setStepIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
    }
  };

  const activateTriggerProductTour =
    selectedMenu == OperationMenu.DASHBOARD
      ? undefined
      : (): void => setTriggerProductTour(OperationMenu.CUSTOMISATION);

  const emptyOperation = (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          p: 7,
          display: "flex",
          alignItems: "center",
          backgroundColor: "error.light",
          boxShadow: 0,
          borderWidth: 2,
          borderColor: "error.main",
        }}
      >
        {/* @ts-ignore */}
        <img src={ErrorLogo} alt="payment-success" />
        <Typography
          variant="h2"
          color="error.dark"
          sx={{fontWeight: "bold", ml: 3}}
        >
          {t("operations.main_page.empty")}
        </Typography>
      </Card>
    </Box>
  );

  return loading ? <Loader /> : operation == null ? emptyOperation :
    <Box sx={{height: "100vh", backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Box sx={{maxHeight: "100vh", overflowY: "auto"}}>
        <Box sx={{height: 153, backgroundColor: "ornament.light"}}>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", px: 7, py: 5}}>
            <SecondLevelMenu menus={menus} />
            <Box>
              <SecondaryBigButton
                label={t("operations.dashboard.buy_points")}
                icon={EuroSymbolIcon}
                position={IconPosition.LEFT}
                href={operationUrl(PRIVATE_URL.WALLETS_BUY_POINTS, operationId!)}
                sx={{mr: 2}}
              />
              <AccessToShopButton operation={operation} />
            </Box>
          </Box>
        </Box>
        <Box sx={{mx: 7, marginTop: "-51px"}}>
          <ProductTour
            tour="customisation"
            steps={steps}
            stepIndex={stepIndex}
            run={triggerProductTour !== null}
            handleProductTour={handleProductTour}
          />
          <OperationBand operation={operation} index={index} activateTriggerProductTour={activateTriggerProductTour}/>
          <Box sx={{mt: 5, mb: 7}}>
            {selectedMenu === OperationMenu.DASHBOARD &&
              <OperationDashboard operation={operation} startProductTour={(): void => setTriggerProductTour(OperationMenu.DASHBOARD)}/>
            }
            {selectedMenu === OperationMenu.CUSTOMISATION &&
              <OperationCustomisation operation={operation} ongoingTransactionsCount={ongoingTransactionsCount} startProductTour={(): void => setTriggerProductTour(OperationMenu.CUSTOMISATION)}/>
            }
          </Box>
        </Box>
        <Footer/>
      </Box>
    </Box>;
}
