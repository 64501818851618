import React, {useContext, useEffect, useState} from 'react';
import {RequestFilter, RequestOrder} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import List from "../../patterns/list/list";
import {GridEnrichedColDef, GridValueGetterParams, GridFilterModel, GridFilterListIcon, useGridApiRef} from "@mui/x-data-grid-pro";
import {columnDefinition, ColumnType, enumFilterWithoutNull} from "../../patterns/list/listUtils";
import emptyTransactions from "../../assets/lists/empty_transactions.svg";
import {Box, Chip, Menu, Typography} from "@mui/material";
import {TAlert} from "../../patterns/list/listSnackbar";
import {getCompanyPointsMovements, getCompanyWalletsUsed, renameWallets} from '../../services/WalletService';
import {TCompany, TCompanyWallet} from '../../interfaces/company';
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {TWalletTransactionType} from '../../interfaces/wallet';
import {ChipCellProps} from "../../patterns/list/listUtils";
import StarsIcon from "@mui/icons-material/Stars";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {getExportCompanyWalletTransactions} from '../../services/ImportService';
import {getExportDocument} from '../../common/methods/exportDocument';
import ListMenuItem from '../../patterns/list/listMenuItem';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {PrimarySmallButton} from '../../components/buttons/mainButton';
import {QuickFilterType} from "../../interfaces/quickFilter";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {IconPosition} from "../../components/buttons/buttonProps";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";

export const typeProps = (category: TWalletTransactionType): ChipCellProps => {
  switch (category) {
  case TWalletTransactionType.UNITARY_CREDIT:
  case TWalletTransactionType.IMPORT_CREDIT:
    return {
      icon: <StarsIcon sx={{width: 18, height: 18}}/>,
      iconColor: "success.main",
      color: "success.main",
      backgroundColor: "success.light"
    }
  case TWalletTransactionType.UNITARY_DEBIT:
  case TWalletTransactionType.IMPORT_DEBIT:
    return {
      icon: <PriorityHighIcon sx={{width: 18, height: 18}}/>,
      iconColor: "warning.main",
      color: "warning.main",
      backgroundColor: "warning.light",
    }
  case TWalletTransactionType.TRANSFER:
    return {
      icon: <SyncAltIcon sx={{width: 18, height: 18, transform: 'rotate(90deg)'}}/>,
      iconColor: "neutral.dark",
      color: "neutral.dark",
      backgroundColor: "ornament.dark",
    }
  case TWalletTransactionType.EXPIRATION:
    return {
      icon: <PriorityHighIcon sx={{width: 18, height: 18}}/>,
      iconColor: "neutral.dark",
      color: "neutral.dark",
      backgroundColor: "ornament.dark",
    }
  case TWalletTransactionType.POINTS_PURCHASE:
    return {
      icon: <AccountBalanceWalletIcon sx={{width: 18, height: 18}}/>,
      iconColor: "success.dark",
      color: "success.dark",
      backgroundColor: "success.light",
    }
  case TWalletTransactionType.DELETE:
    return {
      icon: <CloseIcon sx={{width: 18, height: 18}}/>,
      iconColor: "error.dark",
      color: "error.dark",
      backgroundColor: "error.light",
    }
  default:
    return {
      icon: <StopCircleIcon sx={{width: 18, height: 18}}/>,
      iconColor: "neutral.dark",
      color: "neutral.dark",
      backgroundColor: "neutral.light",
    }
  }
}

export default function WalletsPointsMovements(props: { company: TCompany}): JSX.Element {
  const {company} = props;
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const apiRef = useGridApiRef();
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [walletsUsed, setWalletsUsed] = useState<{id: string, name: string}[]>([]);

  const [quickFilters, setQuickFilters] = useState<GridFilterModel>({items: []});
  const [quickFilterSelected, setQuickFilterSelected] = useState<QuickFilterType|null>(null);

  const [actionsAnchorEl, setActionsAnchorEl] = useState<undefined|HTMLElement>(undefined);

  const handleActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = (): void => {
    setActionsAnchorEl(undefined);
  };

  useEffect(() => {
    document.title = t("tab_title.operation.wallets.list");
    loadWallets();
  }, []);

  const loadTransactions = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TCompanyWallet[]}> => {
    return getCompanyPointsMovements(company.id, page, maxItems, search, order, filters).then((res) => {
      return {totalItems: res.totalItems, items: res.items};
    });
  }

  const loadWallets = (): void => {
    getCompanyWalletsUsed(company.id).then((res) => {
      setWalletsUsed(res);
    });
  }

  const exportTransactions = (): Promise<void> => {
    return getExportCompanyWalletTransactions(company.id!)
      .then(response => {
        getExportDocument(response)
      })
  }

  const typeColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "transactions", "type", 250),
      type: "singleSelect",
      filterOperators: enumFilterWithoutNull,
      filterable: true,
      sortable: true,
      valueOptions: Object.keys(TWalletTransactionType).map(type => ({value: type, label: t(`operation_participants.transactions.type.${type.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const type = typeProps(item.row.type);
        return <Chip icon={type.icon}
          sx={{backgroundColor: type.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: type.iconColor}}}
          label={<Typography variant="body2" color={type.color} sx={{fontWeight: "regular"}}>
            {t("operation_participants.transactions.type." + item.value.toLowerCase(), {date: type.date})}
          </Typography>}/>
      }
    }
  }

  const walletNameColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "transactions", "wallet.name", 200, ColumnType.STRING, true, false, true),
      filterOperators: enumFilterWithoutNull,
      valueOptions: walletsUsed.map((wallet) => {
        if (wallet.id == company.defaultWallet.id && wallet.name == "default") {
          return {value: wallet.name, label: t("wallets.config.default_wallet.name")};
        } else if (wallet.id == company.expiredPointsWallet.id) {
          return {value: wallet.name, label: t("wallets.config.expired_points_wallet.name")};
        } else {
          return {value: wallet.name, label: wallet.name};
        }
      }),
      valueGetter: (item: GridValueGetterParams): string => {
        return (item.row.wallet.id != store.global.company.defaultWallet!.id && item.row.wallet.id != store.global.company.expiredPointsWallet!.id) ? item.row.wallet.name : renameWallets(company, store.global.company.wallets, t).filter(wallet => wallet.id === item.row.wallet.id)[0].name
      }
    }
  }

  const exportAction = <ListMenuItem label={t("wallets.points_movements.export")} icon={FileDownloadIcon} action={(): any => exportTransactions()}/>

  const columns = [
    columnDefinition(t, "transactions", "createdAt", 200, ColumnType.DATE_TIME, true, false, true),
    walletNameColumn(),
    columnDefinition(t, "transactions", "amount", 150, ColumnType.EUROS, true, false, true),
    typeColumnDefinition(),
    columnDefinition(t, "transactions", "reason", 600, ColumnType.STRING, false, false, false),
  ]

  const updateFilters = (dateStart: string, dateEnd: string, filterSelected: QuickFilterType): void => {
    if (filterSelected === quickFilterSelected) {
      setQuickFilters({items: []});
      setQuickFilterSelected(null);
    } else {
      setQuickFilters({items: [{columnField: "createdAt", operatorValue: "after", value: dateStart}, {columnField: "createdAt", operatorValue: "before", value: dateEnd}]})
      setQuickFilterSelected(filterSelected);
    }
  }

  const quickFilterAction = <Box>
    <PrimarySmallButton label={t("operation_gifts_history.actions.quick_filter")} action={handleActionsClick}
      icon={GridFilterListIcon} position={IconPosition.LEFT} sx={{mr: 1}}/>
    <Menu id="actions-menu" open={actionsAnchorEl != null} onClose={handleActionsClose} anchorEl={actionsAnchorEl}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}} transformOrigin={{vertical: "top", horizontal: "right"}}
      sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
      <ListMenuItem label={t("operation_participants.actions.quick.last_week")} icon={quickFilterSelected === QuickFilterType.LASTWEEK ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'), QuickFilterType.LASTWEEK) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.actual_month")} icon={quickFilterSelected === QuickFilterType.ACTUALMONTH ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf("month").format('YYYY-MM-DD'), QuickFilterType.ACTUALMONTH) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_month")} icon={quickFilterSelected === QuickFilterType.LASTMONTH ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), QuickFilterType.LASTMONTH) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_quarter")} icon={quickFilterSelected === QuickFilterType.LASTQUARTER ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'Q').startOf('Q').format('YYYY-MM-DD'), moment().subtract(1, 'Q').endOf('Q').format('YYYY-MM-DD'), QuickFilterType.LASTQUARTER) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_year")} icon={quickFilterSelected === QuickFilterType.LASTYEAR ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'), QuickFilterType.LASTYEAR) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.actual_year")} icon={quickFilterSelected === QuickFilterType.CURRENTYEAR ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().startOf('year').format('YYYY-MM-DD'), moment().add(1, 'day').startOf("day").format('YYYY-MM-DD'), QuickFilterType.CURRENTYEAR) }}/>
    </Menu>
  </Box>

  return <>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: 5}}>
      <Typography variant="body1" color="neutral.main" dangerouslySetInnerHTML={{__html: t("wallets.points_movements.subtitle")}}/>
    </Box>
    <List
      translationPrefix="wallets"
      columns={columns}
      defaultOrder={{field: 'createdAt', sort: 'desc'}}
      apiRef={apiRef}
      loadRows={loadTransactions}
      height="100%"
      emptyListIcon={emptyTransactions}
      reload={reload}
      setReload={setReload}
      alerts={alerts}
      setAlerts={setAlerts}
      filters={quickFilters}
      sx={{width: "100%", mb: 3}}
      enableSelection={false}
      listActions={[exportAction]}
      globalActions={[quickFilterAction]}
    />
  </>
}