import {TAdmin} from "./admin";
import {TCompanyAware, TLifecycle} from "./traits";

export interface TWalletTransaction extends TLifecycle, TCompanyAware {
  amount: number;
  reason: string;
  status: TWalletTransactionStatus;
  option?: TWalletTransactionOption;
  reference?: string;
  odooSaleOrderId: number;
  admin?: TAdmin;
  operation: {id: string};
  wallet: {id: string};
}

export interface TWalletTransactionPayment {
  stripeSecret: string;
  paymentIntentId: string;
}

export enum TWalletTransactionStatus {
  ON_GOING = 'ONGOING',
  PAID = 'PAID',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export enum TWalletTransactionOption {
  CB = 'CB',
  TRANSFER = 'TRANSFER',
}

export enum TWalletTransactionType {
  UNITARY_CREDIT = 'UNITARY_CREDIT',
  UNITARY_DEBIT = 'UNITARY_DEBIT',
  IMPORT_CREDIT = 'IMPORT_CREDIT',
  IMPORT_DEBIT = 'IMPORT_DEBIT',
  EXPIRATION = 'EXPIRATION',
  POINTS_PURCHASE = 'POINTS_PURCHASE',
  TRANSFER = 'TRANSFER',
  DELETE = 'DELETE'
}
